import { Routes } from '@angular/router';

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => import('./offer/page/page.component'),
  },
  {
    path: ':landingpageId',
    loadComponent: () => import('./offer/page/page.component'),
  },
  {
    path: ':landingpageId/vermittlungspraemie',
    loadComponent: () => import('./headhunter/page/page.component'),
    children: [
      {
        path: 'abholen/:headhunterId',
        loadComponent: () => import('./headhunter/request/request.component'),
      },
      {
        path: 'ausbezahlen/:headhunterId/:bountyId',
        loadComponent: () => import('./headhunter/payout/payout.component'),
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];
