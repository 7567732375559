import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { environment } from '../../environments/environment';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  public getTranslation(lang: string) {
    return forkJoin([
      this.http.get<Translation>(
        `${environment.baseUrl}/assets/translations/public.${lang}.json`
      ),
      this.http.get<Translation>(
        `${environment.baseUrl}/assets/ui/translations/ui.de.json`
      ),
      this.http.get<Translation>(
        `${environment.baseUrl}/assets/core/translations/core.de.json`
      ),
    ]).pipe(
      map((resp: Translation[]) => ({
        ...resp[0],
        ...resp[1],
        ...resp[2],
      }))
    );
  }
}
