import { APP_ROUTES } from './app.routes';
import { provideRouter } from '@angular/router';
import {
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import {
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  importProvidersFrom,
  LOCALE_ID,
  StaticProvider,
} from '@angular/core';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslocoHttpLoader } from './transloco/transloco.loader';
import { getTranslocoConfig } from '@core/shared/config/transloco.config';
import { provideTransloco, translocoConfig } from '@jsverse/transloco';
import { IconsModule } from './icons/icons.module';
import { getProviders } from '@core/shared/config/app-providers.config';
import { provideShareButtonsOptions, withConfig } from 'ngx-sharebuttons';

registerLocaleData(localeDE);

const LOCALE_PROVIDERS: StaticProvider[] = [
  {
    provide: LOCALE_ID,
    useValue: 'de-DE',
  },
  {
    provide: DEFAULT_CURRENCY_CODE,
    useValue: 'EUR',
  },
];
export const appConfig: ApplicationConfig = {
  providers: [
    provideShareButtonsOptions(
      withConfig({
        sharerMethod: 'window',
      })
    ),
    importProvidersFrom(IconsModule),
    ...getProviders(environment),
    ...LOCALE_PROVIDERS,
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideAnimations(),
    provideRouter(APP_ROUTES),
    provideTransloco({
      config: translocoConfig(getTranslocoConfig(environment)),
      loader: TranslocoHttpLoader,
    }),
    provideClientHydration(),
  ],
};
