import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  InjectionToken,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { JbdCoreTitleService } from '@core/services/title/title.service';
import { VERSION } from '../environments/version';
import { JbdCoreDocumentsService } from '@core/services/documents/documents.service';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'jbd-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class JbdAppComponent implements OnInit {
  constructor(
    private documentsService: JbdCoreDocumentsService,
    private titleService: JbdCoreTitleService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<unknown>
  ) {
    this.titleService.setRouteTitle('GENERAL.FALLBACK_ROUTE_TITLE');
  }

  public ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.documentsService.init();
      this.setAppVersion();
    }
  }

  private setAppVersion(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).appVersion = VERSION;
  }
}
