export const environment = {
  name: 'qs',
  production: true,
  sentryId:
    'https://7db01a7ff06c43f0b783b06d8f667272@o390785.ingest.sentry.io/6039427',
  sentryTracesSampleRate: 0.05,
  baseUrl: 'http://localhost',
  serverUrl: 'https://qs-backend-booking2.jobodo.de',
  shortLinkUrl: 'https://qs-public-booking2.jobodo.de',
  appDetails: {
    type: 'public',
  },
};
