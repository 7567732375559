import {
  faFacebookF,
  faLinkedin,
  faTwitter,
  faXing,
} from '@fortawesome/free-brands-svg-icons';
import { faCopy, faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import {
  faCheck,
  faExternalLink,
  faShareAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { faSadTear } from '@fortawesome/pro-duotone-svg-icons';

// note: this is the new "𝕏" icon for twitter
faTwitter.icon = [
  512,
  512,
  [],
  'e61b',
  'M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z',
];

export const ICONS = [
  faCheck,
  faCopy,
  faExternalLink,
  faFacebookF,
  faLinkedin,
  faQuestionCircle,
  faSadTear,
  faShareAlt,
  faTwitter,
  faXing,
];
