import { environment } from './environments/environment';
import { VERSION } from './environments/version';
import { bootstrapApplication } from '@angular/platform-browser';
import { JbdAppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { enableSentry } from '@core/utils/sentry/sentry.handler';

if (environment.name !== 'local' && environment.name !== 'production') {
  enableSentry({
    dsn: environment.sentryId,
    release: VERSION,
    environment: environment.name,
    tracesSampleRate: environment.sentryTracesSampleRate,
    serverUrl: environment.serverUrl,
  });
}

bootstrapApplication(JbdAppComponent, appConfig).catch((err) =>
  console.error(err)
);
